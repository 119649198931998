import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { NotificationSettings } from '@common/model/notificationSettings';
import { AccountNotification, MarketingPartner, ProfilePatchOp, ProfilePatchPath, ServicePartner, UserProfilePathOptions } from '@common/profile/profile.interfaces';
import { UserService } from '@common/user/user.service';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { NotificationService } from "@pages/account/components/notification/notification.service";
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { catchError, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';
import { FormControl, UntypedFormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ConsentType, LanguageOptions, MarketingConsents, NOTIFICATION_SELECTS, NotificationSelect, SelectedConsent } from './notification-options.consts';
import { DOCUMENT } from "@angular/common";
import { DialogService } from "@kit/dialog/dialog.service";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { Market, getCountryCode } from "@common/language/language.const";
import { ApiProfileService } from '@common/profile/api-profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface TripNotificationSelectOption {
  value: number;
  name: string;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemNotificationSettingsComponent extends AemBaseBlockComponent implements OnInit, AfterViewInit {
  public userNotificationSettings$: Observable<AccountNotification[]> = this.notificationService.userNotificationSettings$;
  public notificationSelects = [...NOTIFICATION_SELECTS];
  public languageNotificationSelects = [
    {
      name: this.translateService.instant('pages.NOTIFICATION.OPTIONS.EN'),
      value: LanguageOptions.EN
    },
    {
      name: this.translateService.instant('pages.NOTIFICATION.OPTIONS.FR'),
      value: LanguageOptions.FR
    }
  ];
  public languageNotificationSelectControl: FormControl = this.formBuilder.control(null);
  public consentType = ConsentType;

  // for visa
  public isVisaUser: boolean = getCountryCode(this.document.location.host) === Market.FR;

  public get residence(): string {
    return this.userService.location;
  }

  public get isGlobalMarketingConsent(): boolean {
    return (this.residence === Market.DE || this.residence === Market.NL || this.residence === Market.AT);
  }

  constructor(
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,
    private readonly apiProfileService: ApiProfileService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(AEM_DATA) data: NotificationSettings,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.userService.reloadUserNotificationInfo$().pipe(take(1)).subscribe();
    this.userService.reloadUserInfo$();
    this.initFormControls();
    this.initServiceNotificationOptions();
    this.serviceNotificationSubscription();
    this.initServiceNotificationSettings();

    if(this.isVisaUser) {
      this.initLanguageNotificationSettings();
      this.initLanguageNotificationSubscription();
    }
  }

  public ngAfterViewInit(): void {
    const allianzGroupLink = this.document?.getElementById('allianz-group-link');

    allianzGroupLink?.setAttribute('style', 'text-decoration: underline; color: #000; cursor: pointer');
    allianzGroupLink?.addEventListener('click', (event: MouseEvent) => this.openAllianzGroupPopup(event));
  }

  public getUserMarketingConsents(userNotificationSettings: AccountNotification[]): MarketingConsents {
    return userNotificationSettings
      .filter((notification: AccountNotification) => notification.consentType.includes('MARKETING'))
      .reduce((acc, cur) => ({ ...acc, [cur.consentType]: cur.consentValue }), {});
  }

  public getMarketing(userNotificationSettings: AccountNotification[], consentType: ConsentType): AccountNotification {
    return userNotificationSettings
      .find((notification: AccountNotification) => notification.consentType === consentType);
  }

  public switchMarketingNotification(userNotificationSettings: AccountNotification[], consentType: ConsentType, event: Event): void {
    event.preventDefault();

    const marketingNotification = this.getMarketing(userNotificationSettings, consentType);
    const notificationParams = this.getMarketingNotificationAttributes(marketingNotification);

    if (!marketingNotification.consentValue) {
      this.saveNotification(notificationParams, marketingNotification.partnerName);
    } else {
      this.unsubscribeFlow(notificationParams, marketingNotification.partnerName);
    }
  }

  private serviceNotificationSubscription(): void {
    this.notificationSelects.forEach((notification: any) => {
      notification.formControl.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((value: number) => {
          const notificationParams = this.getNotificationAttributes(notification, value);

          if (value) {
            this.saveNotification(notificationParams, notification.partnerName)
          } else {
            this.unsubscribeServiceNotification(notificationParams, notification.partnerName);
          }
        });
    })
  }

  private getNotificationAttributes(notification: AccountNotification, value: number): AccountNotification[] {
    const smsConsent = [1, 3].includes(value);
    const emailConsent = [2, 3].includes(value);

    return [
      {
        partnerName: notification.partnerName,
        consentType: ConsentType.EMAIL,
        consentValue: emailConsent,
        isBlocked: notification.isBlocked
      },
      {
        partnerName: notification.partnerName,
        consentType: ConsentType.SMS,
        consentValue: smsConsent,
        isBlocked: notification.isBlocked
      },
      {
        partnerName: notification.partnerName,
        consentType: ConsentType.PUSH,
        consentValue: this.getPushSettingsByPartner(notification.partnerName),
        isBlocked: notification.isBlocked
      }
    ];
  }

  private getPushSettingsByPartner(partner: string): boolean {
    const notificationSettings = this.userService.userNotification$.getValue();

    return notificationSettings.find((settings: AccountNotification) =>
      settings.partnerName === partner && settings.consentType === ConsentType.PUSH
    )?.consentValue;
  }

  private getMarketingNotificationAttributes(notification: AccountNotification): AccountNotification[] {
    return [
      {
        partnerName: notification.partnerName,
        consentType: notification.consentType,
        consentValue: !notification.consentValue
      }
    ];
  }

  private saveNotification(payload: AccountNotification[], partnerName: ServicePartner | MarketingPartner): void {
    this.notificationService.saveNotificationSettings$(payload, partnerName)
      .pipe(take(1))
      .subscribe();
  }

  private unsubscribeFlow(payload: AccountNotification[], partnerName: ServicePartner | MarketingPartner): void {
    this.notificationService.unsubscribeFromNotification(payload, partnerName)
      .pipe(take(1))
      .subscribe();
  }

  private unsubscribeServiceNotification(payload: AccountNotification[], partnerName: MarketingPartner | ServicePartner): void {
    this.notificationService.unsubscribeServiceNotification$(payload, partnerName)
      .pipe(take(1))
      .subscribe();
  }

  private initServiceNotificationSettings(): void {
    this.notificationSelects.map((notification: NotificationSelect) => {
      this.userNotificationSettings$.pipe(
        map((userNotificationSettings: AccountNotification[]) => {
          const formControl = notification.formControl;
          const selectedConsent = userNotificationSettings
            .filter((userNotificationSetting: AccountNotification) => userNotificationSetting.partnerName === notification.partnerName)
            .map((option) => ({
              consentType: option.consentType,
              consentValue: option.consentValue,
              isBlocked: option.isBlocked
            }));

            notification.selectedConsent = selectedConsent;
          return { selectedConsent, formControl };
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((notificationSelects: any) => {
        const option = this.takeConfigNotificationOption(notificationSelects.selectedConsent);
        notificationSelects.formControl.setValue(option, { emitEvent: false });
      })
    })
  }

  private takeConfigNotificationOption(options: SelectedConsent[]): number {
    const notificationSms = options.find((option: SelectedConsent) => option.consentType === ConsentType.SMS).consentValue;
    const notificationEmail = options.find((option: SelectedConsent) => option.consentType === ConsentType.EMAIL).consentValue;
    let selectedOption = 0;

    if (notificationSms && notificationEmail) selectedOption = 3;
    if (!notificationSms && notificationEmail) selectedOption = 2;
    if (notificationSms && !notificationEmail) selectedOption = 1;

    return selectedOption;
  }

  private initServiceNotificationOptions(): void {
    this.notificationSelects.forEach((notification) => {
      notification.selectOptions$ = forkJoin(
        notification.defaultOptions.map((option) => {
          return this.translateService.get(option.title).pipe(
            map((translatedName: string) => {
              return {
                value: option.value,
                name: translatedName
              };
            })
          );
        })
      )
    });
  }

  private initFormControls(): void {
    this.notificationSelects.forEach((notification) => {
      notification.formControl = this.formBuilder.control(null);
    });
  }

  private openAllianzGroupPopup(event: Event): void {
    event.preventDefault();
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('pages.NOTIFICATION.ALLIANZ_GROUP_DIALOG.TEXT'),
    });
  }

  private initLanguageNotificationSettings(): void {
    const userLanguage = this.userService.userData.language.toUpperCase();
    const defaultOption = this.languageNotificationSelects.find(
      (option) => LanguageOptions[option.value].toUpperCase() === userLanguage
    );

    if (defaultOption) {
      this.languageNotificationSelectControl.setValue(defaultOption.value, { emitEvent: false });
    }
  }

  private initLanguageNotificationSubscription(): void {
    this.languageNotificationSelectControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap((value: LanguageOptions) => {
        const requestOptions: UserProfilePathOptions = {
          op: ProfilePatchOp.REPLACE,
          path: ProfilePatchPath.LANGUAGE,
          value: LanguageOptions[value]
        };

        return this.apiProfileService.updateUserProfile([requestOptions]).pipe(
          catchError(() => of([]))
        );
      }),
      switchMap(() => this.userService.reloadUserInfo$()),
    ).subscribe();
  }
}
