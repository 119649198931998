import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, shareReplay } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { PastInsurancePolicies } from 'src/app/common/model/pastInsurancePolicies';
import { AemBaseBlockComponent } from 'src/app/kit/aem-base-block/aem-base-block';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { Policy } from '../../policy.interface';
import { setRouterData } from '@kit/utils/router.utils';
import { dateToEndDay } from '@kit/utils/date.utils';

@Component({
  selector: 'app-insurance-past-policies',
  templateUrl: './insurance-past-policies.component.html',
  styleUrls: ['./insurance-past-policies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancePastPoliciesComponent extends AemBaseBlockComponent {
  public policies$: Observable<Policy[]> = this.policyService.getPolicies().pipe(
    map((policies: Policy[]) => this.filterPoliciesByEndDate(policies)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly displayStep = 5;
  public shownPolicyCount = this.displayStep;

  constructor(
    private readonly policyService: ApiInsurancePolicyService,
    @Inject(AEM_DATA) public aemData: PastInsurancePolicies,
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService,
  ) {
    super(aemData);
  }

  public showMorePolicies(): void {
    this.shownPolicyCount += this.displayStep;
  }

  public showLessPolicies(): void {
    this.shownPolicyCount -= this.displayStep;
  }

  public onViewDetails(policyNumber: string, openPolicyNumber: string): void {
    if (openPolicyNumber) {
      this.policyService.showOpenPolicyPopUp();

      return;
    }

    const policyCode: string = btoa(policyNumber);

    this.policyService.getPolicyDetails$(policyNumber)
      .subscribe(() => this.router.navigate([setRouterData(
        this.bootstrapService.link.insurancePolicy,
        { policyId: policyCode },
      )]));
  }

  private filterPoliciesByEndDate(policies: Policy[]): Policy[] {
    return policies.filter((policy: Policy) =>
      dateToEndDay(new Date(policy.endDate)).valueOf() < dateToEndDay(new Date()).valueOf());
  }
}
