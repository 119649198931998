<section class="section">
  <div class="shell" [formGroup]="policyForm">
    <div class="number-input">
      <div class="number-input_title h5 m-h3">
        {{ 'pages.MY_INSURANCE.TITLES.ENTER_POLICY_NUMBER' | translate }}
      </div>
      <div class="number-input_text t-normal m-t-normal">
        <app-safe-html [content]="'pages.MY_INSURANCE.DESCRIPTIONS.ADD_EXISTING_POLICY_NUMBER' | translate"></app-safe-html>
      </div>
      <app-label [label]="'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate"
                 [required]="true">
        <app-input [placeholder]="'pages.MY_INSURANCE.PLACEHOLDERS.POLICY_NUMBER' | translate"
                   [maxLength]="20"
                   formControlName="contractNumber">
        </app-input>
        <app-field-error controlName="contractNumber"></app-field-error>
      </app-label>
      <div class="number-input_check-boxes">
        <app-checkbox formControlName="shareAccept">
          <app-safe-html [content]="'pages.MY_INSURANCE.LABELS.AGREEING' | translate"></app-safe-html>
        </app-checkbox>
        <app-field-error controlName="shareAccept"></app-field-error>
      </div>
      <app-field-error [control]="policyForm" [isInBlock]="true"></app-field-error>
      <div class="number-input_buttons">
        <app-button class="number-input_button"
                    type="full-width"
                    (click)="onSubmit()">
          {{ 'pages.MY_INSURANCE.ACTIONS.FIND_POLICY' | translate }}
        </app-button>
        <app-button class="number-input_button"
                    type="full-width"
                    [appAemRouterLink]="bootstrapService.link.myInsurancePolicies"
                    theme="bordered">
          {{ 'pages.MY_INSURANCE.ACTIONS.CANCEL' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</section>

