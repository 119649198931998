<section class="section">
  <div class="shell" *ngIf="policyDetails$ | async as policyDetails">
    <div class="details">
      <div class="details_title h5 m-h3 weight-bold m-h3">
        {{ 'pages.MY_INSURANCE.TITLES.POLICY_INFORMATION' | translate }}
      </div>
      <div class="policy-info">
        <div class="policy-info_row">
          <div class="h6 m-t-normal weight-bold policy-info_title">
            {{ 'pages.MY_INSURANCE.LABELS.POLICY_NAME' | translate }}
          </div>
          <div class="t-normal m-t-normal policy-info_data">
            {{ policyDetails.policyName || '-' }}
          </div>
        </div>
        <div class="policy-info_row">
          <div class="h6 m-t-normal weight-bold policy-info_title">
            {{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }}
          </div>
          <div class="t-normal m-t-normal policy-info_data">
            {{ policyDetails.policyNumber || '-' }}
          </div>
        </div>
        <div class="policy-info_row">
          <div class="h6 m-t-normal weight-bold policy-info_title">
            {{ 'pages.MY_INSURANCE.LABELS.DATE_START' | translate }}
          </div>
          <div class="t-normal m-t-normal policy-info_data">
            {{ policyDetails.startDate | dateFormat : 'fullSpelled' }}
          </div>
        </div>
        <div class="policy-info_row">
          <div class="h6 m-t-normal weight-bold policy-info_title">
            {{ 'pages.MY_INSURANCE.LABELS.DATE_END' | translate }}
          </div>
          <div class="t-normal m-t-normal policy-info_data">
            {{ policyDetails.endDate | dateFormat : 'fullSpelled' }}
          </div>
        </div>
        <div class="policy-info_row">
          <div class="h6 m-t-normal weight-bold policy-info_title">
            {{ 'pages.MY_INSURANCE.LABELS.PURCHASE_DARE' | translate }}
          </div>
          <div class="t-normal m-t-normal policy-info_data">
            {{ policyDetails.purchaseDate | dateFormat : 'fullSpelled' }}
          </div>
        </div>
      </div>
      <ng-container *ngIf="policyDetails?.insuredParties?.length">
        <div class="details_title h5 weight-bold m-h3">
          {{ 'pages.MY_INSURANCE.TITLES.INSURED_TRAVELER' | translate }}
        </div>
        <div class="policy-info">
          <div *ngFor="let traveler of policyDetails.insuredParties"
               class="policy-info_row">
            <div class="h6 m-t-normal weight-bold policy-info_title_traveler">
              {{ 'pages.MY_INSURANCE.LABELS.CREDENTIALS' | translate }}
            </div>
            <div class="t-normal m-t-normal policy-info_data">
              {{ traveler.firstName }} {{ traveler.lastName }} ({{ traveler.birthDate | dateFormat : 'fullSpelled' }})
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="policyDetails.origin === 'TRAVEL'">
        <div class="details_title h5 weight-bold m-h3">
          {{ 'pages.MY_INSURANCE.TITLES.POLICY_HOLDER_DETAILS' | translate }}
        </div>
        <div class="policy-info">
          <div class="policy-info_row">
            <div class="h6 m-t-normal weight-bold policy-info_title">
              {{ 'pages.MY_INSURANCE.LABELS.NAME' | translate }}
            </div>
            <div class="t-normal m-t-normal policy-info_data">
              {{ policyDetails?.contractHolder?.firstName || '-' }} {{ policyDetails?.contractHolder?.lastName }}
            </div>
          </div>
          <div class="policy-info_row">
            <div class="h6 m-t-normal weight-bold policy-info_title">
              {{ 'pages.MY_INSURANCE.LABELS.DATE_OF_BIRTH' | translate }}
            </div>
            <div class="t-normal m-t-normal policy-info_data">
              {{ (policyDetails?.contractHolder?.birthDate | dateFormat : 'fullSpelled') || '-' }}
            </div>
          </div>
          <div class="policy-info_row">
            <div class="h6 m-t-normal weight-bold policy-info_title">
              {{ 'pages.MY_INSURANCE.LABELS.CONTACT_NUMBER' | translate }}
            </div>
            <div class="t-normal m-t-normal policy-info_data">
              {{ policyDetails?.contractHolder?.phone || '-' }}
            </div>
          </div>
          <div class="policy-info_row">
            <div class="h6 m-t-normal weight-bold policy-info_title">
              {{ 'pages.MY_INSURANCE.LABELS.EMAIL' | translate }}
            </div>
            <div class="t-normal m-t-normal policy-info_data">
              {{ policyDetails?.contractHolder?.email || '-' }}
            </div>
          </div>
        </div>
      </ng-container>
      <app-button (click)="removePolice()" size="s" theme="bordered" type="normal" icon="thrash" class="hide">
        {{ 'pages.MY_INSURANCE.ACTIONS.REMOVE_POLICY' | translate }}
      </app-button>
    </div>

    <div *ngIf="policyDetails?.documents?.length" class="files">
      <div class="h5 m-h3 files_title">
        {{ 'pages.MY_INSURANCE.TITLES.POLICY_DOCUMENTS' | translate }}
      </div>
      <div *ngFor="let file of policyDetails.documents" class="file">
        <div class="t-normal h4 file_title">{{ file.name }}</div>
        <div class="t-normal m-t-normal">PDF</div>
        <app-button type="icon-button"
                    icon="download"
                    size="s"
                    (click)="getDocument(file)">
        </app-button>
      </div>
    </div>
  </div>
</section>
