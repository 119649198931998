import { Component, OnInit, ChangeDetectionStrategy, DestroyRef } from '@angular/core';
import { catchError, filter, Observable, switchMap, throwError } from 'rxjs';
import { PolicyDetails, PolicyDocument } from '../../policy.interface';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@common/user/user.service';
import { DialogService } from '@kit/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogRef } from '@kit/dialog/dialog.interfaces';

@Component({
  selector: 'app-insurance-policy-details',
  templateUrl: './insurance-policy-details.component.html',
  styleUrls: ['./insurance-policy-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancePolicyDetailsComponent implements OnInit {
  public policyDetails$: Observable<PolicyDetails>;

  private readonly policyNumber = atob(this.route.firstChild.snapshot.params.policyId);
  private readonly userEmail = this.userService.userData.email;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly destroyRef: DestroyRef,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.initPolicyDetails();
  }

  public removePolice(): void {
    this.showDeletionDialog().afterClosed$
      .pipe(
        filter(value => value === ConfirmationDialogComponent.CONFIRM),
        switchMap(() => this.policyService.deletePolicy$(this.policyNumber)),
        catchError((err) => {
          this.showErrorPopup();

          return throwError(err);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.router.navigate([this.bootstrapService.link.myInsurancePolicies]))
  }

  public getDocument(doc: PolicyDocument): void {
    //TODO

    // this.policyService.getDocument(doc, this.policyDetails.idToken).pipe(
    //   catchError((err: HttpErrorResponse) => {
    //     if (err.status === 404) {
    //       this.timer?.unsubscribe();
    //
    //       return this.getDialogFlow();
    //     }
    //
    //     return of(null);
    //   }),
    //   takeUntilDestroyed(this.destroyRef)
    // ).subscribe(data => data?.content && this.downloadFile(data.content));
  }

  // private downloadFile(data: string): void {
  //   const arrayBuffer = this.base64ToArrayBuffer(data);
  //   const blob = new Blob([arrayBuffer], {type: "application/pdf"});
  //   const link = window.URL.createObjectURL(blob);
  //
  //   window.open(link, '_blank');
  // }

  private base64ToArrayBuffer(base64: string): Uint8Array {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);

    binaryString.split('').forEach((char, i) => {
      bytes[i] = char.charCodeAt(0);
    });

    return bytes;
  }

  private initPolicyDetails(): void {
    this.policyDetails$ = this.policyService.getPolicyDetails$(this.policyNumber);
  }

  private showDeletionDialog(): DialogRef<ConfirmationDialogComponent> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.TITLE'),
      info: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.MESSAGE'),
      importantInfo: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.CONFIRM'),
      cancelTitle: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.CANCEL'),
      reverseButtonPosition: true,
    })
  }

  private showErrorPopup(): DialogRef<ConfirmationDialogComponent> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('errors.CEAZ006'),
      confirmTitle: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.OK'),
    })
  }
}

